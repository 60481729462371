.swiper {
  width: 100%;
  height: 100%;
}

.swiper-button-next::after {
  display: none;
}

.swiper-button-prev::after {
  display: none;
}

.swiper-pagination-bullet {
  width: var(--swiper-pagination-bullet-width,var(--swiper-pagination-bullet-size,32px));
  height: var(--swiper-pagination-bullet-height,var(--swiper-pagination-bullet-size,5px));
  border-radius: 2px
}

.swiper-pagination {
  position: initial;
}

.swiper-pagination-bullet-active {
  background-color: #1c18af;
}