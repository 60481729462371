* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: 'Rubik', sans-serif;
}

.navbar {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  position: fixed;
  top: 0;
  z-index: 999;
  border-bottom: 1px solid #808080;
  background-color: #fff;
  width: 100%;
}
  
.navbar-container {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 1500px;
}
  
.navbar-logo {
  cursor: pointer;
  height: 40px;
  width: 125px;
}

.menu-icon {
  display: none;
  color: #05037a;
}
  
.fa-bars {
  color: #05037a;
}
  
.nav-menu {
  display: flex;
  list-style: none;
  width: 57vw;
  justify-content: space-evenly;
  margin: 0 1.5rem;
}
  
.nav-item {
  height: 70px;
  cursor: pointer;
}
  
@media screen and (max-width: 1200px) {
  .navItems {
    font-size: 1.1rem !important;
    min-width: 120px !important;
  }
}

@media screen and (max-width: 1280px) {
  .nav-logo {
    margin-left: 18px;
  }
  
  .nav-menu {
    margin: 0 0.8rem;
  }

  .nav-links {
    padding: 0.5rem 0.5rem;
  }
  
  .buttons {
    margin-right: 1rem !important
  }
}
  
@media screen and (max-width: 960px) {
  .navbar {
    min-height: 110px;
  }
  
  .navbar-logo {
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(0%, 40%);
    margin-left: 15px;
  }

  .menu-icon {
    display: block;
    position: absolute;
    right: 0;
    transform: translate(-100%, 15%);
    cursor: pointer;
    z-index: 999;
  }

  .fa-times {
    font-size: 2rem;
  }

  .nav-menu {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    height: 90vh;
    position: fixed;
    top: 110px;
    left: -130%;
    opacity: 1;
    transition: all 0.5s ease;
  }

  .nav-menu.active {
    left: -13px;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
    background-color: #05037a;
  }

}

@media screen and (max-width: 600px) {
  .menu-icon {
    transform: translate(-100%, 0%) !important;
  }

  .navbar-logo {
    transform: translate(0%, 50%);
    margin-left: 10px;
    height: 35px !important;
    width: 115px !important;
  }

  .MuiSvgIcon-fontSizeLarge {
    font-size: 2rem !important;
  }
}