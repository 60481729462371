/* custom-scrollbar */
.custom-scrollbar {
    overflow-y: scroll;
    margin-right: 1rem;
    margin-bottom: 1rem;
}
  
.custom-scrollbar::-webkit-scrollbar {
    width: 15px;
    border-radius: 7.5px;
}
  
.custom-scrollbar::-webkit-scrollbar-track {
    background-color: #eeeef7;
    margin-top: 8rem;
}
  
.custom-scrollbar::-webkit-scrollbar-thumb {
    background-color: #1c18af;
    border-radius: 7.5px;
}
  
.custom-scrollbar::-webkit-scrollbar-thumb:hover {
    background-color: #1c18af;
}

/* payment card horizontal scroll - AddPayment Component*/
.scroll-content::-webkit-scrollbar {
    width: 0.5em;
    height: 0.5em;
}