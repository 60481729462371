.react-datepicker__day--in-selecting-range:not(.react-datepicker__day--in-range),
.react-datepicker__month-text--in-selecting-range:not(.react-datepicker__day--in-range),
.react-datepicker__quarter-text--in-selecting-range:not(.react-datepicker__day--in-range),
.react-datepicker__year-text--in-selecting-range:not(.react-datepicker__day--in-range) {
  background-color: rgb(28, 24, 175);
  color: #fff;
}

.react-datepicker__day--in-range {
  background-color: rgb(28, 24, 175);
  color: #fff !important;
}

.react-datepicker__day--selected,
.react-datepicker__day--keyboard-selected {
  background-color: rgb(28, 24, 175);
  color: #fff !important;
}

.react-datepicker__header {
  border-bottom: 0px solid #aeaeae;
  background-color: #fff;
}

.react-datepicker__day {
  color: #3f526d
}

.react-datepicker__day:hover {
  background-color: #6c5dd3;
  color: #fff;
}

.react-datepicker {
  border: 0;
}

.time-picker-box {
  transition: background-color 0.3s ease; /* Smooth transition */
}

.time-picker-box:hover {
  background-color: #6c5dd3;
}