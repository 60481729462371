@media screen and (max-width: 960px) {
  .lamsaLogoContainer {
    display: flex;
    justify-content: flex-end !important;
  }

  .grid-item {
    display: flex;
    justify-content: center;
    align-items: center;
  }

}

@media screen and (max-width: 600px) {
  .container {
    padding: 0 0.5rem !important;
  }

  .hr {
    padding: 1rem 0 !important;
  }

  .gridItem1 {
    margin-top: 0.5rem !important;
  }

  .termsContainer {
    gap: 0.5rem !important;
  }

  .lamsaLogoWhite {
    width: 90px !important;
    height: 25px !important;
  }

  .typography {
    font-size: 13px !important;
  }

  .instagramIcon{ 
    width: 15px !important;
    height: 15px !important;
  }

  .linkedinIcon {
    margin: 0px 0.8rem !important;
    width: 15px !important;
    height: 15px !important;
  }

  .twitterIcon {
    width: 13px !important;
    height: 13px !important;
  }

}