#cont {
  display: flex;
  overflow: hidden;
  flex-wrap: nowrap;
}
.layout {
  height: 100px;
  border-top: 1px solid #979797;
  border-bottom: 1px solid #979797;
  display: grid;
  place-items: center;
  position: relative;
  background: #fff;
  flex-basis: 23.8% !important;
}
.layoutAr {
  height: 100px;
  place-items: center;
  position: relative;
  background: #fff;
  flex-basis: 23.8% !important;
  border-top: 1px solid #979797;
  border-bottom: 1px solid #979797;
  display: grid;
}

@media (max-width: 600px) {
  .layout {
    height: 45px;
    flex-basis: 23% !important;
  }
}
@media (max-width: 600px) {
  .layoutAr {
    flex-basis: 23% !important;
    height: 45px;
  }
}

.layoutAr:nth-child(1) {
  z-index: 4;
  border-right: 1px solid #979797;
}
.layout:nth-child(1) {
  border-left: 1px solid #979797;
  z-index: 4;
}

.layout.stepActive {
  background:#342fb6;
  color: #fff;
}
.layout.previousStepActive {
  background: #6c5dd3;
  color: #fff;
}
.layoutAr.stepActiveAr {
  color: #fff;
  background: #342fb6;
}
.layoutAr.previousStepActiveAr {
  color: #fff;
  background: #6c5dd3;
}
.layoutAr::before {
  --size: 49px;
  left: -49px;
  top: 0;
  border-top: var(--size) solid transparent;
  border-bottom: var(--size) solid transparent;
  border-right: var(--size) solid #fff;
  content: "";
  position: absolute;
}
.layout::before {
  --size: 50px;
  content: "";
  position: absolute;
  right: -49px;
  top: 0;
  border-top: var(--size) solid transparent;
  border-bottom: var(--size) solid transparent;
  border-left: var(--size) solid #fff;
  /* white */
}

@media (max-width: 600px) {
  .layoutAr::before {
    --size: 22.5px;
    right: -0.5px;
  }
}
@media (max-width: 600px) {
  .layout::before {
    --size: 22.5px;
    right: -21.5px;
  }
}

.stepActiveAr::after {
  content: "";
  background: #342fb6;
  position: absolute;
  transform: rotate(225deg);
}
.previousStepActiveAr::after {
  content: "";
  background: #6c5dd3;
  position: absolute;
  transform: rotate(225deg);
}
.stepActive::after {
  content: "";
  position: absolute;
  background: #342fb6;
  transform: rotate(45deg);
}
.previousStepActive::after {
  content: "";
  position: absolute;
  background: #6c5dd3;
  transform: rotate(45deg);
}
.layoutAr::after {
  --box-size: 100px;
  content: "";
  width: var(--box-size);
  height: var(--box-size);
  border-top: 1px solid #979797;
  border-left: 1px solid #979797;
  position: absolute;
  left: -29px;
  top: 0;
  transform: rotate(-44deg);
}
.layout::after {
  --box-size: 100px;
  content: "";
  position: absolute;
  right: -30px;
  top: 0;
  width: var(--box-size);
  height: var(--box-size);
  border-top: 1px solid #979797;
  border-right: 1px solid #979797;
  transform: rotate(45deg);
}

@media (max-width: 600px) {
  .layoutAr::after {
    left: -12px;
    top: -3px;
    --box-size: 50px;
  }
}
@media (max-width: 600px) {
  .layout::after {
    --box-size: 50px;
    right: -12px;
    top: -3px;
  }
}

.layout:nth-child(2) {
  z-index: 3;
}

.layout:nth-child(3) {
  z-index: 2;
}

.layout:nth-child(4) {
  z-index: 1;
}
.layoutAr:nth-child(2) {
  z-index: 3;
}

.layoutAr:nth-child(3) {
  z-index: 2;
}

.layoutAr:nth-child(4) {
  z-index: 1;
}
.text {
  z-index: 99;
}

.iconimage {
  width: 30px;
  height: 30px;
}
.textlayoutAr {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: end;
  /* flex-direction: column; */
}
@media (min-width: 768px) {
  .textlayoutAr {
    justify-content: center;
    flex-direction: column;
    margin-right: 49px;
  }
}
@media (min-width: 900px) {
  .textlayoutAr {
    justify-content: center;
    flex-direction: column;
    margin-right: 45px;
  }
}
.textlayout {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: end;
  /* flex-direction: column; */
}
@media (min-width: 768px) {
  .textlayout {
    justify-content: center;
    flex-direction: column;
    margin-left: 49px;
  }
}
@media (min-width: 900px) {
  .textlayout {
    justify-content: center;
    flex-direction: column;
    margin-left: 45px;
  }
}
