tr {
    height: 78px !important;
}

table {
    width: 100%;
}

a {
    word-break: break-all;
}
  
th {
    min-width: 125px;
    padding: 0 !important;
    overflow-x: auto;
    white-space: nowrap;
    text-align: center !important;
}
  
@media screen and (max-width: 960px) {
    /* compare lamsa features */
    .tableHeading {
        padding-left: 1rem !important;
    }
    /* monthly */
    .gridContainer {
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

@media screen and (max-width: 767px) {
    /* compare lamsa features */
    .pricingPlan {
        line-height: 48px !important;
    }

    .checkIcon {
        height: 20px !important;
        width: 20px !important;
    }
}

@media screen and (max-width: 600px) {
    .headingText {
        font-size: 24px !important;
        margin-bottom: 2rem !important;
        margin-top: 0 !important;
    }
}